<template>
	<div>
		<div class="filter-controls">
			<div class="controls-wrapper">
				<select v-model="currentFilter">
					<option value="salutation">Anrede</option>
					<option value="firstname">Vorname</option>
					<option value="lastname">Nachname</option>
					<option value="email">E-Mail</option>
					<option value="moreInformation">Andere Angaben</option>
				</select>
				<input type="text" placeholder="Filter" v-model="currentFilterValue" />
				<button class="export" @click="exportCSV">
					<img src="@/assets/icons/export_csv.svg" />
					<span>Exportieren als CSV</span>
				</button>
			</div>
			<p class="row-amount">
				{{ data.length }} Einträge
				<template v-if="currentFilterValue != ''">
					/ Gefundene Einträge {{ sortedRows.length }}
				</template>
			</p>
		</div>
		<div class="table-wrapper">
			<table class="personal-data-table">
				<thead class="head">
					<tr>
						<th @click="sort('salutation')">Anrede</th>
						<th @click="sort('firstname')">Vorname</th>
						<th @click="sort('lastname')">Nachname</th>
						<th @click="sort('email')">E-Mail</th>
						<th style="width: 100%" @click="sort('moreInformation')">
							Andere Angaben
						</th>
						<th @click="sort('email')"></th>
					</tr>
				</thead>
				<tbody class="body" v-if="sortedRows.length != 0">
					<template v-for="(item, index) in sortedRows">
						<tr :key="index">
							<td>{{ item.personalData.salutation }}</td>
							<td>{{ item.personalData.firstname }}</td>
							<td>{{ item.personalData.lastname }}</td>
							<td>{{ item.personalData.email }}</td>
							<td>{{ item.personalData.moreInformation }}</td>
							<td>
								<button
									@click="showAnswers(index + 10000)"
									class="show-answers"
								>
									<img src="@/assets/icons/details.svg" />
								</button>
							</td>
						</tr>
						<tr
							v-show="showDetails.includes(index + 10000)"
							:key="index + 10000"
						>
							<td colspan="6">
								<table class="answer-data-table">
									<thead class="head">
										<th style="min-width: 25vw">Frage</th>
										<th style="width: 100%">Antwort/en</th>
									</thead>
									<tbody class="body">
										<tr v-for="(aItem, aIndex) in item.answers" :key="aIndex">
											<td v-html="getAnswerQuestion(aItem)"></td>
											<td v-html="getAnswerResult(aItem)"></td>
										</tr>
									</tbody>
								</table>
							</td>
						</tr>
					</template>
				</tbody>
				<tbody class="body" v-else>
					<tr>
						<td style="text-align: center" colspan="6">
							Keine Ergebnisse gefunden!
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import { parseAsync } from 'json2csv';
const {
	transforms: { unwind }
} = require('json2csv');
import FileDownloader from 'js-file-download';
export default {
	name: 'DataTable',
	props: {
		data: Array,
		survey: Object
	},
	data: function () {
		return {
			currentSort: 'firstname',
			currentSortDir: 'asc',

			currentFilter: 'firstname',
			currentFilterValue: '',

			showDetails: []
		};
	},
	computed: {
		sortedRows: function () {
			var result = this.data.slice(0).sort((a, b) => {
				let aVal = a.personalData[this.currentSort];
				let bVal = b.personalData[this.currentSort];

				if (this.currentSortDir == 'asc')
					return (
						(aVal === null) - (bVal === null) ||
						+(aVal > bVal) ||
						-(aVal < bVal)
					);
				else
					return (
						(aVal === null) - (bVal === null) ||
						-(aVal > bVal) ||
						+(aVal < bVal)
					);
			});

			if (this.currentFilterValue != '')
				result = result.filter((x) =>
					x.personalData[this.currentFilter]
						?.toLowerCase()
						.includes(this.currentFilterValue)
				);

			return result;
		}
	},
	watch: {
		currentFilterValue: function () {
			this.showDetails = [];
		}
	},
	methods: {
		sort: function (column) {
			if (column == this.currentSort)
				this.currentSortDir = this.currentSortDir == 'asc' ? 'desc' : 'asc';

			this.currentSort = column;
			this.showDetails = [];
		},
		showAnswers: function (index) {
			var realIndex = this.showDetails.indexOf(index);
			if (realIndex !== -1) this.showDetails.splice(realIndex, 1);
			else this.showDetails.push(index);
		},
		getAnswerQuestion: function (answer) {
			let page = this.survey.pages.find((x) => x.id == answer.pageID);
			if (page == null) return 'Frage fehlt in der Datenbank';

			return page.data;
		},

		getAnswerResult: function (answer) {
			let page = this.survey.pages.find((x) => x.id == answer.pageID);
			if (page == null) return 'Frage fehlt in der Datenbank';

			if (answer.elementType == 1) {
				let element = page.elementData.find((x) => x.id == answer.elementID);
				return element.data;
			} else if (answer.elementType == 2) {
				let elements = [];

				answer.selection.forEach((selection) => {
					let element = page.elementData.find((x) => x.id == selection);
					if (element != null) elements.push(element);
				});

				let output = '';
				elements.forEach((element) => {
					output += element.data + ', ';
				});

				return output;
			} else if (answer.elementType == 3) {
				let element = page.elementData;
				return element.data;
			}
		},
		exportCSV: function () {
			const fields = [
				'salutation',
				'firstname',
				'lastname',
				'email',
				'moreInformation',
				'answers.question',
				'answers.answer'
			];
			const opts = {
				fields,
				transforms: [unwind({ paths: ['answers'], blankOut: true })],
				quote: ''
			};

			let data = [];

			this.sortedRows.forEach((item) => {
				let line = {
					salutation: item.personalData.salutation,
					firstname: item.personalData.firstname,
					lastname: item.personalData.lastname,
					email: item.personalData.email,
					moreInformation: item.personalData.moreInformation,
					answers: []
				};

				item.answers.forEach((aItem) => {
					var regex = /( |<([^>]+)>)/gi;
					line.answers.push({
						question: this.getAnswerQuestion(aItem).replaceAll(regex, ''),
						answer: this.getAnswerResult(aItem)
					});
				});

				data.push(line);
			});

			parseAsync(data, opts).then((csv) =>
				FileDownloader(csv, 'ergebnis_' + this.survey.name + '.csv')
			);
		}
	}
};
</script>

<style lang="scss" scoped>
.table-wrapper {
	height: calc(100vh - 200px);
	overflow: auto;

	border: 1px solid $unnamed-color-999999;
	border-radius: 4px;
}

.filter-controls {
	@include flex-between-center;

	.controls-wrapper {
		@include flex-left;
		margin-bottom: 10px;
	}

	select,
	input {
		border: 1px solid $unnamed-color-999999;
		background: #262626 0% 0% no-repeat padding-box;
		border-radius: 4px;
		text-align: left;
		padding: 8px;

		margin-right: 10px;
	}

	select {
		@include bold-text-uppercase-15;
	}

	input {
		@include normal-text-15;
	}

	.export {
		display: block;

		height: 37px;
		width: 280px;

		padding-right: 20px;

		position: relative;
		text-align: right;

		background: $unnamed-color-fdc300 0% 0% no-repeat padding-box;
		border-radius: 4px;
		border: none;

		color: $unnamed-color-000000;
		cursor: pointer;

		span {
			@include bold-text-uppercase-20;
			color: inherit;
		}

		img {
			width: 24px;
			height: 24px;

			position: absolute;
			left: 20px;
			top: 50%;
			transform: translateY(-50%);
		}

		&:hover {
			background: #333333 0% 0% no-repeat padding-box;
			border: 2px solid black;
			border-radius: 4px;

			color: white;
		}
	}

	.row-amount {
		@include bold-text-uppercase-15;
	}
}

.personal-data-table {
	display: block;
	border-collapse: collapse;
	background: #262626 0% 0% no-repeat padding-box;

	> .body td,
	> .head th {
		border: 1px solid #dddddd;
		text-align: left;
		padding: 8px;
		cursor: pointer;
	}

	> .head th {
		@include bold-text-uppercase-15;
		white-space: nowrap;
	}

	> .body td {
		@include normal-text-15;
		word-wrap: break-word;

		.show-answers {
			height: 30px;
			width: 30px;

			background: $unnamed-color-333333 0% 0% no-repeat padding-box;
			border-radius: 4px;
			opacity: 1;
			border: none;

			cursor: pointer;

			@include flex-center;

			img {
				width: 20px;
				height: 20px;
			}
		}
	}
}

.answer-data-table {
	display: block;
	border-collapse: collapse;
	background-color: white;

	border: 1px solid $unnamed-color-999999;
	background: #262626 0% 0% no-repeat padding-box;
	border-radius: 4px;

	> .body td,
	> .head th {
		border: 1px solid #dddddd;
		text-align: left;
		padding: 8px;
		cursor: pointer;
	}

	> .head th {
		@include bold-text-uppercase-15;
		white-space: nowrap;
	}

	> .body td {
		@include normal-text-15;
		word-wrap: break-word;
	}
}
</style>
